<template>
  <main class="home">
  <div style="mix-blend-mode: color-dodge;perspective:600px;idth:max-content;margin:0rem auto;animation:spin 10s linear infinite;">
    <h1 style="color:#4f0;width:max-content;margin:0rem auto;padding;1rem 2rem 0rem;line-height:5rem;width:250px;transform:rotatex(-20deg);  text-shadow: 0px 0px 10px;
}">YESCLIP</h1>
  </div>  <a href="https://noclip.org"> <div style="  mix-blend-mode: color-dodge;perspective:600px;width:max-content;margin:0rem auto;animation:spin 9s linear infinite;">

   <h1 style="background:#ffd300;color:black;width:max-content;margin:0rem auto;padding;1rem 2rem 0rem;line-height:5rem;width:250px;transform:rotatex(200deg);box-shadow: 0px 0px 10px;">NOCLIP</h1>
 </div> </a>
 </main>
</template>
<style>
@keyframes spin{
  0%{transform:rotatey(-10deg)}
  100%{transform:rotatey(10deg)}
}
@keyframes glitch{
  0%{background-position-x:0%;}
  100%{background-position-x:100%;}
}
a{text-decoration:unset!important;}
.home{background-image:url("/clip.gif");animation:glitch 10s linear infinite;background-size:cover;perspective:600px;background-blend-mode:overlay;background-color:#020202;color:white;height:100vh;width:100vw;display:flex;flex-flow:column;justify-content:center;}
</style>
